<template>
  <v-container>
    <v-data-table  v-if="!loading"
      :headers="headers"
      :items="evaluations"
      class="elevation-1"
      disable-sort
      disable-filtering
      disable-pagination
    >
      <template v-slot:item="{ item }">
        <tr>
          <td>{{ new Date(item.date.seconds*1000).toLocaleDateString()
           }}</td>
          <td v-for="header in headers.slice(1)" :key="header.value">
            <span :style="colorStyle(item.result[header.value.replace('result.', '')], header.value.replace('result.', ''))">
              {{ item.result[header.value.replace('result.', '')] || '-' }}
            </span>
          </td>
        </tr>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';

export default {
  data() {
    return {
      evaluations: [],
      headers: [],
      userId: '51449897',
      lastValues: {},
      loading:true,
    };
  },
  async created() {
    await this.fetchEvaluations();
  },
  methods: {
    async fetchEvaluations() {
      const db = getFirestore();
      const q = query(collection(db, "evaluationsHistory"), where("id", "==", this.userId));
      const querySnapshot = await getDocs(q);
      let resultKeys = new Set();

      this.evaluations = querySnapshot.docs.map(doc => doc.data()).sort((a, b) => a.date.seconds - b.date.seconds);

      this.evaluations.forEach(evaluation => {
        if (evaluation.result) {
          Object.keys(evaluation.result).forEach(key => {
            resultKeys.add(key);
            if (!this.lastValues.hasOwnProperty(`result.${key}`)) {
              this.lastValues[`result.${key}`] = evaluation.result[key];
            }
          });
        }
      });

      
      this.headers = [{ text: 'Date', value: 'date' }, ...Array.from(resultKeys).map(key => ({ text: key, value: `result.${key}` }))];

      this.loading = false;
    },
    colorStyle(value, key) {
      const lastValue = this.lastValues[`result.${key}`];
      const color = value > lastValue ? 'green' : value < lastValue ? 'red' : '';
      this.lastValues[`result.${key}`] = value; // Immediately update last value
      return `color: ${color}`;
    }
  }
}
</script>
